/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Map = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
        <h2 className="text-center">We Cover The Entire State Of:</h2>
        <div className="flex flex-row">
          <div className="flex flex-col w-[50%] m-4">
            <h4 className="text-center">Missouri, EE.UU.</h4>
            <iframe
              src={rpdata?.dbPrincipal?.location?.[1].url}
              className="w-full h-[500px]"
            />
          </div>
          <div className="flex flex-col w-[50%] m-4">
            <h4 className="text-center">Kansas, EE.UU.</h4>
            <iframe
              src={rpdata?.dbPrincipal?.location?.[2].url}
              className="w-full h-[500px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
