import React from 'react'
import {BiBuildingHouse} from "react-icons/bi"
import {GiFamilyHouse} from "react-icons/gi"
import {RiHotelFill} from "react-icons/ri"

function AreasWorks() {
  return (
    <div className='w-4/5 mx-auto flex justify-center mt-2'>
      <div className='flex flex-col items-center justify-center mx-5'>
        <BiBuildingHouse className='bg-2 p-3 rounded-full text-[60px] text-white hover:bg-[#009FE3] transition-all ease-in delay-150'/>
        <span className='text-[25px] font-bold'>Apartments</span>
      </div>
      <div className='flex flex-col items-center justify-center mx-5'>
        <GiFamilyHouse className='bg-2 p-3 rounded-full text-[60px] text-white hover:bg-[#009FE3] transition-all ease-in delay-150'/>
        <span className='text-[25px] font-bold'>Nursing Homes</span>
      </div>
      <div className='flex flex-col items-center justify-center mx-5'>
        <RiHotelFill className='bg-2 p-3 rounded-full text-[60px] text-white hover:bg-[#009FE3] transition-all ease-in delay-150'/>
        <span className='text-[25px] font-bold'>Hotels</span>
      </div>
    </div>
  )
}

export default AreasWorks
